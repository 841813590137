<template>
  <div class="event-regist">
    <div class="events-title">EVENTS の登録</div>
    <div id="input-events">
      <div class="inline-flex w-full">
        <div class="history-block block">
          <span>登録中のイベント</span>
          <div class="history">
            <div class="events-dl" v-for="(item, key) in events" :key="key">
              <table>
                <tr>
                  <td>{{ item.fmtStartDatetime.dateFmt }}</td>
                  <td rowspan="2">{{ item.title }}</td>
									<i class="ph ph-caret-right" @click="deploymentEvents(item)"></i>
                </tr>
                <tr>
                  <td>{{ item.fmtStartDatetime.timeFmt }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="input-block">
          <table class="input-table">
            <tr>
              <td>非表示</td>
              <td><input type="checkbox" id="events_hide_flag" name="events_hide_flag"></td>
            </tr>
            <tr>
              <td>満員御礼！</td>
              <td><input type="checkbox" id="events_full_house_flag" name="events_full_house_flag"></td>
            </tr>
            <tr>
              <td>日時（開始）</td>
              <td><input type="datetime-local" name="events_datetime_start"></td>
            </tr>
            <tr>
              <td>日時（終了）</td>
              <td><input type="datetime-local" name="events_datetime_end"></td>
            </tr>
            <tr>
              <td>タイトル</td>
              <td><input type="text" name="events_title"></td>
            </tr>
            <tr>
              <td>文章</td>
              <td><textarea name="events_content" id="" cols="30" rows="10"></textarea></td>
            </tr>
            <tr>
              <td>イベントURL（非動作）</td>
              <td><input type="text" name="events_url"></td>
            </tr>
            <tr>
              <td>価格表示</td>
              <td><input type="checkbox" id="events_price_disp_flag" name="events_price_disp_flag"></td>
            </tr>
            <tr>
              <td>プレミアム価格</td>
              <td><input type="number" class="events_price" name="events_premium_price"></td>
            </tr>
            <tr>
              <td>プレミアム価格（URL）</td>
              <td><input type="text" class="" name="events_premium_url"></td>
            </tr>
            <tr>
              <td>ベーシック価格</td>
              <td><input type="number" class="events_price" name="events_basic_price"></td>
            </tr>
            <tr>
              <td>ベーシック価格（URL）</td>
              <td><input type="text" class="" name="events_basic_url"></td>
            </tr>
            <tr>
              <td>サポーター＆非会員価格</td>
              <td><input type="number" class="events_price" name="events_other_price"></td>
            </tr>
            <tr>
              <td>サポーター＆非会員価格（URL）</td>
              <td><input type="text" class="" name="events_other_url"></td>
            </tr>
            <tr>
              <td>小中高価格</td>
              <td><input type="number" class="events_price" name="events_student_price"></td>
            </tr>
            <tr>
              <td>小中高価格（URL）</td>
              <td><input type="text" class="" name="events_student_url"></td>
            </tr>
            <tr>
              <td>小学生価格</td>
              <td><input type="number" class="events_price" name="events_elementary_student_price"></td>
            </tr>
            <tr>
              <td>小学生価格（URL）</td>
              <td><input type="text" class="" name="events_elementary_student_url"></td>
            </tr>
            <tr>
              <td>未就学児価格</td>
              <td><input type="number" class="events_price" name="events_baby_price"></td>
            </tr>
            <tr>
              <td>未就学児価格（URL）</td>
              <td><input type="text" class="" name="events_baby_url"></td>
            </tr>
            <tr>
              <td>申込リンク（価格非表示時）</td>
              <td><input type="text" class="" name="events_application_url"></td>
            </tr>
            <tr>
              <td>画像</td>
              <td>
                <input type="file" @change="eventsFileUpload">
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="imgs">
                <div v-if="events_img_download_url">
                  <img v-bind:src="events_img_download_url" alt="">
                </div>
              </td>
            </tr>
            <tr>
              <td>途中に差し込む画像<br>本文中に「gagagagazou」<br>と打ち込んでください
              </td>
              <td>
                <input type="file" @change="eventsFileUpload2">
              </td>
            </tr>
            <tr>
              <td></td>
              <td class="imgs">
                <div v-if="events_img_download_url2">
                  <img v-bind:src="events_img_download_url2" alt="">
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div class="input-ud-div">
                  <i class="ph ph-trash" @click="deleteData"></i>
                  <i class="ph ph-floppy-disk" @click="eventsPostData"></i>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../main.js";
import { collection, doc, addDoc, setDoc, getDocs, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  name: 'EventRegist',
  components: {
  },
  props: {
  },
  data() {
    return {
      // Events
      events: [],
      events_data_id: String,
      events_img_full_path: [],
      events_img_download_url: [],
      events_img_full_path2: [],
      events_img_download_url2: []
    }
  },
  mounted() {
    this.awaitFunc();
  },
  methods: {
    toDateTime(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return {
        dateFmt: date.getFullYear() + "/" + ("00" + (date.getMonth()+1)).slice(-2) + "/" + ("00" + date.getDate()).slice(-2),
        timeFmt: ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2),
        datetimeVal: date.getFullYear()
                      + "-" + ("00" + (date.getMonth()+1)).slice(-2)
                      + "-" + ("00" + date.getDate()).slice(-2)
                      + "T" + ("00" + date.getHours()).slice(-2)
                      + ":" + ("00" + date.getMinutes()).slice(-2)
      };
    },
    async awaitFunc() {
      this.clearEvents();
      this.events.length = 0;
      this.events_data_id = null;
      const eventsQuerySnapshot = await getDocs(collection(firebase.db, "Events"));
      eventsQuerySnapshot.forEach((doc) => {
        var data = doc.data();
        data.fmtStartDatetime = this.toDateTime(data.startDatetime);
        data.fmtEndDatetime = this.toDateTime(data.endDatetime);
        data.id = doc.id;
        this.events.push(data);
      });
      if (this.events.length > 0) {
        this.events.sort((a, b) => b.startDatetime - a.startDatetime);
      }
    },
    clearEvents() {
      document.getElementById("events_hide_flag").checked = false;
      document.getElementById("events_full_house_flag").checked = false;
      document.getElementsByName("events_title")[0].value = "";
      document.getElementsByName("events_content")[0].value = "";
      document.getElementsByName("events_datetime_start")[0].value = "";
      document.getElementsByName("events_datetime_end")[0].value = "";
      document.getElementById("events_price_disp_flag").checked = true;
      document.getElementsByName("events_premium_price")[0].value = "";
      document.getElementsByName("events_premium_url")[0].value = "";
      document.getElementsByName("events_basic_price")[0].value = "";
      document.getElementsByName("events_basic_url")[0].value = "";
      document.getElementsByName("events_other_price")[0].value = "";
      document.getElementsByName("events_other_url")[0].value = "";
      document.getElementsByName("events_student_price")[0].value = "";
      document.getElementsByName("events_student_url")[0].value = "";
      document.getElementsByName("events_elementary_student_price")[0].value = "";
      document.getElementsByName("events_elementary_student_url")[0].value = "";
      document.getElementsByName("events_baby_price")[0].value = "";
      document.getElementsByName("events_baby_url")[0].value = "";
      document.getElementsByName("events_application_url")[0].value = "";
    },
    deploymentEvents(item) {
      document.getElementById("events_hide_flag").checked = item.hideFlag;
      document.getElementById("events_full_house_flag").checked = item.fullHouseFlag;
      document.getElementsByName("events_title")[0].value = item.title;
      document.getElementsByName("events_content")[0].value = item.content;
      document.getElementsByName("events_datetime_start")[0].value = item.fmtStartDatetime.datetimeVal;
      document.getElementsByName("events_datetime_end")[0].value = item.fmtEndDatetime.datetimeVal;
      document.getElementById("events_price_disp_flag").checked = ("priceDispFlag" in item) ? item.priceDispFlag : true;
      document.getElementsByName("events_premium_price")[0].value = item.premiumPrice;
      document.getElementsByName("events_premium_url")[0].value = item.premiumUrl ? item.premiumUrl : "";
      document.getElementsByName("events_basic_price")[0].value = item.basicPrice;
      document.getElementsByName("events_basic_url")[0].value = item.basicUrl ? item.basicUrl : "";
      document.getElementsByName("events_other_price")[0].value = item.otherPrice;
      document.getElementsByName("events_other_url")[0].value = item.otherUrl ? item.otherUrl : "";
      document.getElementsByName("events_student_price")[0].value = item.studentPrice;
      document.getElementsByName("events_student_url")[0].value = item.studentUrl ? item.studentUrl : "";
      document.getElementsByName("events_elementary_student_price")[0].value = item.elementaryStudentPrice ? item.elementaryStudentPrice : "";
      document.getElementsByName("events_elementary_student_url")[0].value = item.elementaryStudentUrl ? item.elementaryStudentUrl : "";
      document.getElementsByName("events_baby_price")[0].value = item.babyPrice;
      document.getElementsByName("events_baby_url")[0].value = item.babyUrl ? item.babyUrl : "";
      document.getElementsByName("events_application_url")[0].value = item.applicationUrl ? item.applicationUrl : "";

      this.events_data_id = item.id;

      this.events_img_download_url = null;
      this.events_img_full_path = item.imageurl ? item.imageurl : [];
      if (this.events_img_full_path != null) {
        const storage = getStorage();
        var admin = this;
        getDownloadURL(ref(storage, "events-contents-image/" + this.events_img_full_path))
        .then(function(url) {
          admin.events_img_download_url = url;
        })
        .catch((error) => {
          console.log(error);
        });
      }
      this.events_img_download_url2 = null;
      this.events_img_full_path2 = item.imageurl2 ? item.imageurl2 : [];
      if (this.events_img_full_path2 != null) {
        const storage = getStorage();
        var admin2 = this;
        getDownloadURL(ref(storage, "events-contents-image/" + this.events_img_full_path2))
        .then(function(url) {
          admin2.events_img_download_url2 = url;
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    async eventsPostData() {
      const data = {
        hideFlag: document.getElementById("events_hide_flag").checked,
        fullHouseFlag: document.getElementById("events_full_house_flag").checked,
        title: document.getElementsByName("events_title")[0].value,
        content: document.getElementsByName("events_content")[0].value,
        startDatetime: new Date(document.getElementsByName("events_datetime_start")[0].value),
        endDatetime: new Date(document.getElementsByName("events_datetime_end")[0].value),
        updateDate: new Date(),
        imageurl: this.events_img_full_path,
        imageurl2: this.events_img_full_path2,
        priceDispFlag: document.getElementById("events_price_disp_flag").checked,
        premiumPrice: document.getElementsByName("events_premium_price")[0].value,
        premiumUrl: document.getElementsByName("events_premium_url")[0].value,
        basicPrice: document.getElementsByName("events_basic_price")[0].value,
        basicUrl: document.getElementsByName("events_basic_url")[0].value,
        otherPrice: document.getElementsByName("events_other_price")[0].value,
        otherUrl: document.getElementsByName("events_other_url")[0].value,
        studentPrice: document.getElementsByName("events_student_price")[0].value,
        studentUrl: document.getElementsByName("events_student_url")[0].value,
        elementaryStudentPrice: document.getElementsByName("events_elementary_student_price")[0].value,
        elementaryStudentUrl: document.getElementsByName("events_elementary_student_url")[0].value,
        babyPrice: document.getElementsByName("events_baby_price")[0].value,
        babyUrl: document.getElementsByName("events_baby_url")[0].value,
        applicationUrl: document.getElementsByName("events_application_url")[0].value,
      };
      if (this.events_data_id) {
        await setDoc(doc(firebase.db, "Events", this.events_data_id), data);
        alert("イベントデータが更新されました");

      } else {
        data.createDate = new Date();
        const db = collection(firebase.db, "Events");
        await addDoc(db, data);
        alert("イベントデータが登録されました");

      }
      this.awaitFunc();
    },
    async deleteData() {
      if (confirm(document.getElementsByName("events_title")[0].value + "を削除してもいいですか？")) {
        await deleteDoc(doc(firebase.db, "Events", this.events_data_id));
      }
      this.awaitFunc();
    },
    async eventsDeleteData() {
    },
    eventsFileUpload(event) {
      let file = event.target.files[0];

      const storage = getStorage();
      const storageRef = ref(storage, "events-contents-image/" + file.name);
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        this.events_img_full_path = snapshot.metadata.name;
      });
    },
    eventsFileUpload2(event) {
      let file = event.target.files[0];

      const storage = getStorage();
      const storageRef = ref(storage, "events-contents-image/" + file.name);
      uploadBytes(storageRef, file)
      .then((snapshot) => {
        this.events_img_full_path2 = snapshot.metadata.name;
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .inline-menu {
    margin-top: 3vw;
    margin-bottom: 3vw;
  }
  .inline-menu span {
    margin: 0 2vw;
  }
  .select-tab {
    font-weight: bold;
    background-color: #a37200;
  }
  #news-tab-title,
  #events-tab-title {
    padding: 1vw 2vw;
  }

  #input-events,
  #input-news {
    width: 80%;
    margin: 0 auto;
  }
  .history-block {
    width: 30%;
  }
  .history {
    border: 1px solid black;
    height: 32vw;
    background-color: white;
    overflow-y: auto;
    padding-bottom: 2vw;
  }
  .news-dl,
  .events-dl {
    margin: 0.4vw;
    background-color: antiquewhite;
  }
  .events-dl table {
    text-align: left;
    width: 100%;
  }
  .input-block {
    padding-left: 2vw;
    width: 70%;
    display: grid;
  }
  .ud-div {
    position: relative;
    text-align: right;
  }
  .ud-icon {
    width: 1.2vw;
    height: 1.2vw;
    margin: 0.4vw 0.5vw;
  }
  .input-table input {
    width: 100%;
    margin-top: 0.2vw;
    margin-bottom: 0.2vw;
    height: 2vw;
    padding-left: 0.5vw;
  }
  #events_hide_flag,
  #events_full_house_flag,
  #events_price_disp_flag {
    margin-top: 0.2vw;
    margin-bottom: 0.2vw;
    height: 1.5vw;
    padding-left: 0.5vw;
    float: left;
    width: 5%;
  }
  .input-table textarea {
    width: 100%;
    margin-top: 0.2vw;
    margin-bottom: 0.2vw;
    padding-left: 0.5vw;
  }
  .input-table td:nth-child(1) {
    text-align: right;
    padding-right: 2vw;
    width: 30%;
  }
  .input-ud-div {
    position: relative;
  }
  .input-ud-icon {
    width: 1.8vw;
    height: 1.8vw;
    margin: 0.4vw 0.5vw;
  }
  .file-upload {
    background-color: white;
  }
  .imgs {
    height: 15vw;
    width: 100%;
    display: inline-flex;
    overflow-x: auto;
  }
  .imgs img {
    width: 12vw;
    height: auto;
    margin: 0.2vw;
  }
  .event_price {
    width: 40% !important;
    float: left;
  }
  .events-title {
    font-size: 1.5vw;
    font-weight: bold;
    margin-bottom: 1vw;
    margin-top: 2vw;
  }
</style>
