<template>
  <div class="footer">
    <div class="footer-div">
      <table class="footer-table">
        <tr>
          <td><span>Unity 合同会社</span></td>
          <td>
            <a href="https://peraichi.com/landing_pages/view/donaru/" style="color: antiquewhite;" target="_blank">
              どうなる⁉サロン
            </a>
          </td>
          <td rowspan="2">
            <span>
              <a href="https://www.facebook.com/groups/3362753157068603/" target="_blank">
								<i class="ph ph-facebook-logo"></i>
              </a>
              <a href="https://www.instagram.com/farmy_shinsyu/" target="_blank">
								<i class="ph ph-instagram-logo"></i>
              </a>
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <a id="mail-to" href="mailto:contact@donaru.jp?subject=お問い合わせ">contact@donaru.jp</a>
          </td>
          <td>
            <a href="/ryoko" style="color: antiquewhite;" target="_blank">旅行業法に基づく表示</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="llc">
      <span>© 2022 Unity.LLC</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #AF9453;
  font-size: 11pt;
}
.footer {
  margin-top: 2vw;
  width: 100%;
  background-color: #AF9453;
  color: antiquewhite;
}
.footer-div {
  display: inline-flex;
}
.footer-table {
  margin-top: 1vw;
}
.footer-table tr {
  height: 2vw;
}
.footer-table td {
  text-align: left;
}
.footer-table td:nth-child(1) {
  width: 10vw;
  color: antiquewhite;
  padding-right: 2vw;
}
.footer-table td:nth-child(2) {
  vertical-align: top;
  border-left: antiquewhite 1px solid;
  padding-left: 20px;
}
.footer-table td:nth-child(3) {
  text-align: center;
  vertical-align: top;
  width: 15vw;
}
i {
  margin: 0 10px;
  color: antiquewhite;
}
.llc {
  margin-top: 30px;
  padding-bottom: 20px;
}
#mail-to {
  color: antiquewhite;
}
@media screen and (max-width:640px) {
  .footer {
    margin-top: 5vw;
    font-size: 10px;
  }
  .footer-div {
    width: 100%;
  }
  .footer-table {
    position: relative;
    width: 80%;
    margin: 4vw auto 0 auto;
  }
  .footer-table td:nth-child(2) {
    width: 20vw;
  }
}
</style>
