<template>
  <Header/>
  <router-view />
  <Footer/>
</template> 

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      adminMode: false,
      inputCommand: [],
      adminCommand: [38,38,40,40,37,39,37,39,66,65]
    }
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode == 27) {
        this.inputCommand = [];
      } else {
        this.inputCommand.push(event.keyCode);
        if (JSON.stringify(this.inputCommand) == JSON.stringify(this.adminCommand)) {
          this.inputCommand = [];
          document.location.replace("/admin");
        }
      }
    }
  }
}
</script>

<style>
#app {
  /* font-family: 'Kiwi Maru', serif; */
  /* font-family: 'Kaisei HarunoUmi', serif; */
  font-family: 'Zen Maru Gothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #EBDEBC;
  height: 100%;
}
i {
  font-size: 1.5rem;
}
</style>
