<template>
    <div class="header">
      <div v-on:click="toHome" class="mt-3 ml-5 lg:visible invisible">
        <img src="../assets/header_logo.png" alt="logo">
      </div>
      <div class="header-menu invisible lg:visible">
        <router-link class="mr-8" to="/">Home</router-link>
        <router-link class="mr-8" to="/about">About</router-link>
        <router-link class="mr-8" to="/share-farm">シェア農家</router-link>
        <router-link class="mr-8" to="/events">イベント</router-link>
        <router-link class="mr-8" to="/products">商品一覧</router-link>
        <router-link class="mr-8" to="/online-seminar">オンライン説明会</router-link>
        <!-- <router-link class="mr-8" to="/contact">Contact</router-link> -->
        <router-link class="mr-8" to="/company">会社概要</router-link>
				<i class="ph ph-user-circle"></i>

      </div>
      <button type="button" @click="switchMobileMenu" class="mobile-menu-btn absolute items-center lg:hidden right-1 top-5">
				<i class="ph ph-list"></i>
      </button>
      <div class="mobile-menu bg-white absolute w-full h-full">
        <button type="button" @click="switchMobileMenu" class="mobile-menu-hidden-btn absolute items-center lg:hidden right-1 top-5">
					<i class="ph ph-x"></i>
        </button>
        <hr>
        <div class="inline-grid">
          <router-link @click="switchMobileMenu" class="mobile-menu-text" to="/">Home</router-link>
          <router-link @click="switchMobileMenu" class="mobile-menu-text" to="/about">About</router-link>
          <router-link @click="switchMobileMenu" class="mobile-menu-text" to="/share-farm">シェア農家</router-link>
          <router-link @click="switchMobileMenu" class="mobile-menu-text" to="/events">イベント</router-link>
          <router-link @click="switchMobileMenu" class="mobile-menu-text" to="/online-seminar">オンライン説明会</router-link>
          <router-link @click="switchMobileMenu" class="mobile-menu-text" to="/products">商品一覧</router-link>
          <router-link @click="switchMobileMenu" class="mobile-menu-text" to="/company">会社概要</router-link>

        </div>
      </div>
      <div class="account-tab-non-signin">
        <router-link @click="tabHidden" class="account-link" to="/signin-signup">サインイン／ログイン</router-link>
      </div>
      <div class="account-tab-signin inline-grid">
        <router-link @click="tabHidden" class="account-link" to="/user">アカウント</router-link>
        <router-link @click="signout" class="account-link" to="/">ログアウト</router-link>
      </div>
    </div>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

export default {
  name: 'Header',
  props: {},
  methods: {
    toHome() {
      this.$router.push("/");
    },
    switchMobileMenu() {
      var mmb = document.getElementsByClassName("mobile-menu")[0];
      if (mmb.style.visibility == "visible") {
        mmb.style.visibility = "hidden";
      } else {
        mmb.style.visibility = "visible";
      }
    },
    account() {
      const nonSignin = document.getElementsByClassName("account-tab-non-signin")[0];
      const signin = document.getElementsByClassName("account-tab-signin")[0];
      if (nonSignin.style.display == "inline-grid") {
        nonSignin.style.display = "none";
      } else if (signin.style.display == "inline-grid") {
        signin.style.display = "none";
      } else {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
          if (user) {
            nonSignin.style.display = "none";
            signin.style.display = "inline-grid";
          } else {
            nonSignin.style.display = "inline-grid";
            signin.style.display = "none";
          }
        });
      }
    },
    signout() {
      const auth = getAuth();
      signOut(auth)
      .then(() => {
        alert("ログアウトしました");
        this.tabHidden();
      })
      .catch((error) => {
        console.log(error);
      });
    },
    tabHidden() {
      const nonSignin = document.getElementsByClassName("account-tab-non-signin")[0];
      const signin = document.getElementsByClassName("account-tab-signin")[0];
      nonSignin.style.display = "none";
      signin.style.display = "none";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #AF9453;
}
hr {
  position: relative;
  margin: 20vw auto 3vw;
  width: 80%;
}
.header {
  display: inline-flex;
  width: 100%;
  height: 92px;
}
.header-menu {
  position: absolute;
  top: 2.3vw;
  right: 3vw;
}
.admin-fade-in {
  animation-name:fadeInAnime;
  animation-duration:1s;
  animation-fill-mode:forwards;
  opacity:0;
}
.mobile-menu {
  z-index: 900;
  visibility: hidden;
}
.mobile-menu-text {
  font-size: 5vw;
  line-height: 10vw;
}
.menu-outline,
.close-outline {
  width: 3rem;
  height: 3rem;
  color: #AF9453;
}
.account-icon {
  color: #AF9453;
	width: 1.6vw;
  height: 1.6vw;
  vertical-align: bottom;
  z-index: 999;
}
.account-tab-non-signin {
  position: absolute;
  width: 15vw;
  height: 6vw;
  background-color: white;
  right: 5vw;
  top: 5vw;
  z-index: 999;
  border-radius: 6px;
  border: 1px solid #AF9453 ;
  display: none;
}
.account-tab-signin {
  position: absolute;
  width: 15vw;
  height: 12vw;
  background-color: white;
  right: 5vw;
  top: 5vw;
  z-index: 999;
  border-radius: 6px;
  border: 1px solid #AF9453 ;
  display: none;
}
.account-link {
  margin: 2vw 0;
}
@keyframes fadeInAnime{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
